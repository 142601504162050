import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  link: {
    margin: theme.spacing(1),
  },
  linkDisabled: {
    color: grey[500],
    pointerEvents: 'none'
  }
}));

const TranslationBox = (props) => {
  const classes = useStyles();
  const { lang, path } = props;

  return (
    <Paper className={classes.paper}>
      <Link
        component={RouterLink}
        to={`/zh${path}`}
        className={`${classes.link} ${lang.toLowerCase() === 'zh' ? classes.linkDisabled : ''}`}
      >
        中文
      </Link>
      <Link
        component={RouterLink}
        to={`/en${path}`}
        className={`${classes.link} ${lang.toLowerCase() === 'en' ? classes.linkDisabled : ''}`}
      >
        English
      </Link>
    </Paper>
  );
}

export default TranslationBox;