import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import logo from '../assets/preference-logo.png';

import zh from '../assets/i18n/zh.json';
import en from '../assets/i18n/en.json';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    backgroundColor: '#373434'
  },
  logo: {
    maxWidth: '100%'
  },
  link: {
    color: '#fff',
    margin: theme.spacing(1)
  }
}));

const Header = router => {
  const { pathname } = router.location;
  const classes = useStyles();

  useEffect(() => {
    const { hostname } = window.location;
    if (
      !(
        hostname.toLocaleLowerCase() === 'localhost' ||
        hostname.toLocaleLowerCase().includes('pwmfy.com')
      )
    ) {
      window.location.replace('http://www.pwmfy.com');
    }
  });

  let lang = 'zh';
  let trans = zh;

  if (pathname.toLowerCase().startsWith('/en')) {
    lang = 'en';
    trans = en;
  }

  return (
    <div className={classes.container}>
      <Container>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Link component={RouterLink} to={`/${lang}`}>
              <img
                src={logo}
                alt="慶祐繩帶製品 Preference Weaving Manufactory"
                className={classes.logo}
              />
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link
              component={RouterLink}
              to={`/${lang}`}
              className={classes.link}
            >
              {trans['home-title']}
            </Link>
            <Link
              component={RouterLink}
              to={`/${lang}/contact`}
              className={classes.link}
            >
              {trans['contact-title']}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Header);
