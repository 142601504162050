import React from 'react';

import { brown } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { Redirect } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';

import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

import TranslationBox from './TranslationBox';

import zh from '../assets/i18n/zh.json';
import en from '../assets/i18n/en.json';

const useStyles = makeStyles(theme => ({
  map: {
    border: `1px solid ${brown[400]}`,
    height: '360px',
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(3)
  },
  link: {
    margin: theme.spacing(1)
  },
  strong: {
    fontWeight: 'bold'
  }
}));

const Contact = router => {
  const classes = useStyles();

  const { lang } = router.match.params;
  const position = [22.3280312, 114.1624255];

  if (lang.toLowerCase() !== 'en' && lang.toLowerCase() !== 'zh') {
    return <Redirect to="/zh" />;
  }

  let trans = zh;

  if (lang.toLowerCase() === 'en') {
    trans = en;
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="flex-start">
      <Grid item xs={12} md={8}>
        <Hidden mdUp>
          <TranslationBox lang={lang} path="/contact" />
        </Hidden>
        <Map
          center={position}
          zoom={18}
          className={classes.map}
          attributionControl={false}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              慶祐繩帶製品
              <br />
              Preference Weaving Manufactory
            </Popup>
          </Marker>
        </Map>
        <Paper className={classes.paper}>
          <Typography
            variant="h4"
            component="h2"
            paragraph
            dangerouslySetInnerHTML={{ __html: trans['contact-title'] }}
          />
          <Typography
            variant="body1"
            paragraph
            dangerouslySetInnerHTML={{ __html: trans.contact }}
          />
          <Link
            href="https://maps.google.com.hk/maps?ie=UTF8&cid=15128982639479802908&q=Preference+Weaving+Manufactory&iwloc=A&gl=HK&hl=en_uk"
            rel="noreferrer"
            target="_blank"
          >
            {trans['open-google-maps']}
          </Link>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Hidden smDown>
          <TranslationBox lang={lang} path="/contact" />
        </Hidden>
        <Paper className={classes.paper}>
          <Typography
            variant="body1"
            className={classes.strong}
            gutterBottom
            dangerouslySetInnerHTML={{ __html: trans['office-hours-title'] }}
          />
          <Typography
            variant="body2"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: trans['office-hours'] }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Contact;
