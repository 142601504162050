import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Link from '@material-ui/core/Link';
import { Redirect } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';

import TranslationBox from './TranslationBox';

import zh from '../assets/i18n/zh.json';
import en from '../assets/i18n/en.json';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  link: {
    margin: theme.spacing(1),
  },
  strong: {
    fontWeight: 'bold'
  }
}));

const Home = (router) => {
  const { lang } = router.match.params;
  const classes = useStyles();

  if (lang.toLowerCase() !== 'en' && lang.toLowerCase() !== 'zh') {
    return (<Redirect to="/zh" />);
  }

  let trans = zh;

  if (lang.toLowerCase() === 'en') {
    trans = en;
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="flex-start">
      <Grid item xs={12} md={8}>
        <Hidden mdUp>
          <TranslationBox lang={lang} path='/' />
        </Hidden>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h2" gutterBottom dangerouslySetInnerHTML={{ __html: trans['home-title'] }} />
          <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: trans.home }} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Hidden smDown>
          <TranslationBox lang={lang} path='/' />
        </Hidden>
        <Paper className={classes.paper}>
          <Typography variant="body1" className={classes.strong} gutterBottom dangerouslySetInnerHTML={{ __html: trans['office-hours-title'] }} />
          <Typography variant="body2" gutterBottom dangerouslySetInnerHTML={{ __html: trans['office-hours'] }} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Home;