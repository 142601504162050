import React from 'react';
import Leaflet from 'leaflet'
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Container from '@material-ui/core/Container';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import Link from '@material-ui/core/Link';

import Header from './components/Header';
import Home from './components/Home';
import Contact from './components/Contact';

Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/'

const useStyles = makeStyles(theme => ({
  body: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Router>
      <React.Fragment>
        <Header />
        <Container className={classes.body}>
          <Route exact path="/" render={() => (
            <Redirect to="/zh"/>
          )}/>
          <Route exact path="/:lang" component={Home} />
          <Route exact path="/:lang/contact" component={Contact} />
        </Container>
      </React.Fragment>
    </Router>
  );
}

export default App;
